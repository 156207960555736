<div class="row">
  <div class="col-md-4 offset-md-4">
    <div class="guest-form-card card">

      <div class="card-header text-center">
        <h2>Login Here</h2>
      </div>

      <div class="card-body">
        <form #loginForm="ngForm" (ngSubmit)="loginForm.valid && login()">

          <div *ngIf="authFailed" class="alert alert-danger" role="alert">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-exclamation-circle" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
            Incorrect Username or Password
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input id="email" class="form-control" [ngClass]="{ 'is-invalid': email.touched && email.invalid }"
              name="email" #email="ngModel" [(ngModel)]="model.email" required />
            <span class="invalid-feedback">Email can't be blank.</span>
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" id="password" class="form-control"
              [ngClass]="{ 'is-invalid': password.touched && password.invalid }" name="password" #password="ngModel"
              [(ngModel)]="model.password" required />
            <span class="invalid-feedback">Password can't be blank.</span>
          </div>

          <button class="btn btn-primary btn-block" [disabled]="loginForm.invalid" (click)="toggleLoading()">
            <div *ngIf="isLoading else loginButton">
              <div class="spinner-border spinner-border-sm text-light" role="status">
              </div>
            </div>
            <ng-template #loginButton>
              Login
            </ng-template>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>