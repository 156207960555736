<div class="row">
  <div class="col-md-4 offset-md-4">
    <div class="guest-form-card card">

      <div class="card-header text-center">
        <h2>Register Here</h2>
      </div>

      <div class="card-body">
        <form [formGroup]="registerForm" (ngSubmit)="register()">

          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" class="form-control"
              [ngClass]="{ 'is-invalid': registerForm.get('username').touched && registerForm.get('username').invalid }"
              formControlName="username" />
            <span class="invalid-feedback">Username can't be blank.</span>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input id="email" class="form-control"
              [ngClass]="{ 'is-invalid': registerForm.get('email').touched && registerForm.get('email').invalid }"
              formControlName="email" />
            <span class="invalid-feedback">
              <span *ngIf="registerForm.get('email').hasError('required')">Email can't be blank.</span>
              <span *ngIf="registerForm.get('email').hasError('pattern')">Email must be valid.</span>
            </span>
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" id="password"
              [ngClass]="{ 'is-invalid': registerForm.get('password').touched && registerForm.get('password').invalid }"
              class="form-control" formControlName="password" />
            <span class="invalid-feedback">
              <span *ngIf="registerForm.get('password').hasError('required')" class="spanError">Password can't be
                blank.</span>
              <span *ngIf="registerForm.get('password').hasError('symbol')" class="spanError">Password must contain a
                special character.
              </span>
              <span *ngIf="registerForm.get('password').hasError('minlength')" class="spanError">Password must contain
                minimum 4
                characters. </span>
            </span>
          </div>

          <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input type="password" id="confirmPassword" class="form-control"
              [ngClass]="{ 'is-invalid': registerForm.get('confirmPassword').touched && registerForm.get('confirmPassword').invalid }"
              formControlName="confirmPassword" />
            <span class="invalid-feedback">Passwords must match.</span>
          </div>

          <div class="form-group">
            <input type="checkbox" id="merchant" name="merchants" formControlName="isMerchant">
            <label class="ml-1" for="merchant">Merchant Account</label>
          </div>

          <button class="btn btn-primary btn-block" [disabled]="registerForm.invalid">Register</button>
        </form>
      </div>
    </div>
  </div>
</div>