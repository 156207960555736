<div class="row">
  <div class="col-sm offset-sm">
    <div class="guest-form-card card">

      <div class="card-header text-center">
        <h2>Edit a Product</h2>
      </div>

      <div class="card-body">
        <form [formGroup]="editProductForm">

          <div class="form-group">
            <label for="productCodeForEdit">Product Code</label>
            <input id="productCodeForEdit" class="form-control" name="productCodeForEdit" required />
            <span class="invalid-feedback">Product Code can't be blank.</span>
            <span class="invalid-feedback">Can't remove this product.</span>
          </div>

          <div>
            <button class="btn btn-warning btn-block" (click)="findProduct()">Find</button>
          </div>

          <hr>

          <div class="card-body">
            <div class="form-group">
              <label for="name">Name</label>
              <input id="name" class="form-control"
                [ngClass]="{ 'is-invalid': editProductForm.get('name').touched && editProductForm.get('name').invalid }"
                formControlName="name" value="editProductForm.get('name')" />
              <span class="invalid-feedback">Product Name can't be blank.</span>
            </div>

            <div class="form-group">
              <label for="price">Price</label>
              <input id="price" class="form-control"
                [ngClass]="{ 'is-invalid': editProductForm.get('price').touched && editProductForm.get('price').invalid }"
                formControlName="price" value="editProductForm.get('price')" />
              <span class="invalid-feedback">
                <span *ngIf="editProductForm.get('price').hasError('required')">Price can't be blank.</span>
              </span>
            </div>

            <div class="form-group">
              <label for="description">Description</label>
              <input id="description" class="form-control"
                [ngClass]="{ 'is-invalid': editProductForm.get('description').touched && editProductForm.get('description').invalid }"
                formControlName="description" value="editProductForm.get('description')" />
              <span class="invalid-feedback">
                <span *ngIf="editProductForm.get('description').hasError('required')">Email can't be blank.</span>
              </span>
            </div>


            <button class="btn btn-warning btn-block" (click)="editProduct()">Edit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div>

  </div>
</div>