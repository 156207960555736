<div class="row">

  <div class="col-sm-4">
    <app-add-product></app-add-product>
  </div>

  <div class="col-sm-4">
    <app-remove-product></app-remove-product>
  </div>

  <div class="col-sm-4">
    <app-edit-product></app-edit-product>
  </div>
</div>