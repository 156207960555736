<div class="card mb-4 shadow-sm">
  <img class="bd-placeholder-img card-img-top" width="100%" [src]="URL + productItem.image">

  <div class="card-body">
    <h4 class="card-text">
      <strong>{{ productItem.name }}</strong>
    </h4>
    {{ productItem.price | currency:'EUR' }}
    <p class="card-text">{{ productItem.description }}</p>
    <p class="card-text"><small class="text-muted">Product Code: {{ productItem._id }}</small></p>
    <div *ngIf="isLogged && !isMerchant">
      <div class="d-flex justify-content-between align-items-center">
        <button type="button" class="btn btn-sm btn-primary product-btn btn-block" (click)="handleAddToCart()">Add to
          Cart
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cart-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M8.5 5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 .5-.5z" />
            <path fill-rule="evenodd" d="M8 7.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0v-2z" />
            <path fill-rule="evenodd"
              d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
          </svg>
        </button>
      </div>
    </div>
  </div>