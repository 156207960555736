<div class="row">
  <div class="col-md-4 offset-md-4">
    <div class="card">
      <div class="card-header">
        <h3>404 - Page Not Found</h3>
      </div>

      <div class="card-body">
        <p>Oops! The page that you were looking for is not found.</p>
        <p>
          <a routerLink="/">Go Back To Home</a>
        </p>
      </div>
    </div>
  </div>
</div>