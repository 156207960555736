<app-nav></app-nav>

<div class="container">

  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <app-footer></app-footer>
    </div>
  </div>
</div>