<div class="row">
  <div class="col-md-4 offset-md-4">
    <div class="guest-form-card card">

      <div class="card-header text-center">
        <h2>User Profile</h2>
      </div>

      <div class="card-body">
        <form [formGroup]="editUserForm">

          <div class="card-body">
            <div class="form-group">
              <label for="username">Username</label>
              <input id="username" class="form-control"
                [ngClass]="{ 'is-invalid': editUserForm.get('username').touched && editUserForm.get('username').invalid }"
                formControlName="username" value="editUserForm.get('username')" />
              <span class="invalid-feedback">Username can't be blank.</span>
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input id="email" class="form-control"
                [ngClass]="{ 'is-invalid': editUserForm.get('email').touched && editUserForm.get('email').invalid }"
                formControlName="email" value="editUserForm.get('email')" />
              <span class="invalid-feedback">
                <span *ngIf="editUserForm.get('email').hasError('required')">Email can't be blank.</span>
              </span>
            </div>


            <button class="btn btn-warning btn-block" (click)="editUser()">Edit</button>

            <hr>

            <div class="card-body">
              <button class="btn btn-outline-secondary btn-block" [routerLink]="['/shop']" (click)="logout()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd"
                    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                Logout
              </button>
            </div>

            <div class="card-body">
              <button class="btn btn-danger btn-block" (click)="deleteUser()">DELETE PROFILE</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div>

  </div>
</div>