<div class="row">
  <div class="col-sm offset-sm">
    <div class="guest-form-card card">

      <div class="card-header text-center">
        <h2>Add a Product</h2>
      </div>

      <div class="card-body">
        <form [formGroup]="addProductForm" (ngSubmit)="add()">

          <div class="form-group">
            <label for="name" class="form-label">Name</label>
            <input id="name" class="form-control"
              [ngClass]="{ 'is-invalid': (addProductForm.get('name').touched && addProductForm.get('name').invalid), 'is-valid':  addProductForm.get('name').valid }"
              formControlName="name" />
            <span class="invalid-feedback" *ngIf="addProductForm.get('name').hasError('required')">Name can't be
              blank.</span>
          </div>

          <div class="form-group">
            <label for="price">Price</label>
            <input id="price" class="form-control"
              [ngClass]="{ 'is-invalid': (addProductForm.get('price').touched && addProductForm.get('price').invalid), 'is-valid':  addProductForm.get('price').valid }"
              formControlName="price" />
            <span class="invalid-feedback">
              <span *ngIf="addProductForm.get('price').hasError('required')">Price can't be blank.</span>
            </span>
            <span class="invalid-feedback">
              <span *ngIf="addProductForm.get('price').hasError('pattern')">Price must to be numeric.</span>
            </span>
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <input id="description" class="form-control"
              [ngClass]="{ 'is-invalid': (addProductForm.get('description').touched && addProductForm.get('description').invalid), 'is-valid':  addProductForm.get('description').valid }"
              formControlName="description" />
            <span class="invalid-feedback">
              <span *ngIf="addProductForm.get('description').hasError('required')">Description can't be blank.</span>
            </span>
          </div>

          <div class="custom-file">
            <input type="file" name="image" class="custom-file-input" role="button"
              [ngClass]="{ 'is-invalid': (addProductForm.get('image').touched && addProductForm.get('image').invalid), 'is-valid':  addProductForm.get('image').valid }"
              formControlName="image" (change)="onFileSelect($event)" />
            <label class="custom-file-label" for="customFile" role="button">Choose image {{file}}</label>
            <span class="invalid-feedback">
              <span *ngIf="addProductForm.get('image').hasError('required')">Image can't be empty.</span>
            </span>
          </div>

          <button class="btn btn-primary btn-block" [disabled]="addProductForm.invalid" (click)="toggleLoading()">
            <div *ngIf="isLoading else loginButton">
              <div class="spinner-border spinner-border-sm text-light" role="status">
              </div>
            </div>
            <ng-template #loginButton>
              Add
            </ng-template>
          </button>
        </form>
      </div>
    </div>
  </div>
  <div>

  </div>
</div>